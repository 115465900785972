import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

interface Props {
  isShowModalSorting: boolean
  setIsShowModalSorting: Function
  title: string
  onSelected: Function
  loading: boolean
  isFree: boolean
  setIsFree: Function
}

export interface SelectedSortingType {
  sorting?: boolean
  free?: boolean
}

const SortingOfferLotusStoreLoading = () => {
  const styleSkeleton = 'bg-gradient-to-l from-smoke to-white-smoke'

  return (
    <div id="filter-offer-lotus-type-loading" className='animate-pulse flex flex-row mb-[24px] mx-[12px]'>
      <div id="filter-offer-lotus-type-loading-content1" className='flex flex-col items-center'>
        <p id="filter-offer-lotus-type-loading-content1-text1" className={`${styleSkeleton} rounded-lg w-[99px] h-[28px] mb-2 mr-[16px]`} />
      </div>
      <div id="filter-offer-lotus-type-loading-content2" className='flex flex-col items-center'>
        <p id="filter-offer-lotus-type-loading-content1-text1" className={`${styleSkeleton} rounded-lg w-[66px] h-[28px] mb-2`} />
      </div>
    </div>
  )
}

const SortingOfferLotusStore: React.FC<Props> = ({
  isShowModalSorting,
  setIsShowModalSorting,
  title,
  onSelected,
  loading,
  isFree,
  setIsFree
}) => {
  if (loading) {
    return <SortingOfferLotusStoreLoading />
  }

  return (
    <div id='offers-sorting' className='ml-[12px]'>
      <div className='flex flex-row'>
        <div
          className={classNames(
            'flex flex-row border-[1px] px-[8px] py-[4px] rounded-lg mr-[8px] bg-[#CEF3F1] border-[#00BCB4] text-[#00BCB4]',
          )}
          onClick={() => {
            setIsShowModalSorting(!isShowModalSorting)
          }}
        >
          <img
            src={'/icons/sorting-icon.png'}
            alt='sorting-icon'
            className='mr-[4px] w-[18px] h-[18px] m-auto'
          />
          <p className='flex my-auto font-bold text-sm'>{title}</p>
          <img
            src={'/icons/trailing-icon.png'}
            alt='trailing-icon'
            className='ml-[4px] w-[18px] h-[18px] m-auto'
          />
        </div>
        <div className='border-[#E0E0E0] border-[0.5px] mr-[8px]'></div>
        <div
          className={classNames(
            'flex flex-row border-[1px] px-[8px] py-[4px] rounded-lg mr-[8px]',
            {
              'bg-[#CEF3F1] border-[#00BCB4] text-[#00BCB4]': isFree,
              'bg-[#FFFFFF] border-[#E0E0E0] text-[#7D7D7D]': !isFree,
            }
          )}
          onClick={() => {
            setIsFree(!isFree)
            onSelected(!isFree)
          }}
        >
          ฟรี
        </div>
      </div>
    </div>
  )
}

export default SortingOfferLotusStore
